body {

  background-color: white;
        
	.logo {
		position: absolute;
		display: block;
		top: 2rem;
		left: 2rem;
		width: 10rem;
	}

	.user {
		position: absolute;
		display: block;
		top: 3rem;
		right: 2rem;
		width: 10rem;
		text-align: right;
		a {
			color: $black;
		}
		.dropdown-menu {
			padding: 1rem 2rem;
			.dropdown-item {
				padding-left: 0;
				&:hover {
					background-color: transparent;
					color: $darkgray;
				}
			}
		}
		
	}

	#form-login,
	#form-forgot-password,
	#form-reset-password {
		background: white url('https://lcsthlm.s3.eu-central-1.amazonaws.com/woosh/woosh-speed.jpg') center center no-repeat;
		background-size: cover;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		position: relative;
		.form {
			background-color: white;
			border-radius: .5rem;
			padding: 2rem 3rem 2rem 3rem; 
		}
	}

	#form-login {
		.form {
			padding: 3rem 3rem 2rem 3rem; 
		}
	}

	#dashboard {
		min-height: calc(100vh - 8rem);
		.container {
			margin-top: 8rem;
			.card {
				margin: 2rem;
				width: 80%;
				@media (min-width: 415px) {
					width: 18rem;
				}
				border: 0;
				box-shadow: 0 0 1rem 0.5rem rgba(90,98,104,.1);
				.card-img-top {
					height: 10rem;
					background-color: #e9ecef;
					position: relative;
					h5 {
						display: block;
						margin-top: 5rem;
						text-align: center;
						font-weight: bold;
					}
					.users-icon {
						position: absolute;
						bottom: .5rem;
						right: .5rem;
						width: 2rem;
						height: 1rem;
						i {
							color: $black;
							height: 100%;
						}
					}
				}
				.card-body {
					a {
						display:block;
					}
				}
			}
		}
	}
}