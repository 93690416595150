:focus {
  outline: none !important;
  box-shadow: none !important;
}

.hidden {
	display: none;
}

.small-text {
	font-size: 80%;
}

a {
	color: #5a6268;
	&:hover {
		color: #5a6268;
		text-decoration: underline;
	}
}


.form-group {
	position: relative;
	input {
		&.form-control {
			
			&:focus {
				border-color: #5a6268;
			}
			height: calc(1.5rem + 1.5rem + 2px);
			padding-top: 1.25rem;
			background-color: #e9ecef;
			&.has-floating-label {
				+.floating-label {
					position: absolute;
					top: .75rem;
					left: .75rem;
					pointer-events: none;
					font-size: 100%;
					color: rgba(90,98,104,.6);
					transition: all .2s ease;
				}
				&.has-value{
					+.floating-label {
						top: 0.5rem;
						font-size: 70%;
					}
				}
			}
			
		}
	}
}

.custom-control-input {
    position: absolute;
    width: 100%;
		height: 100%;
		margin-left: -1.5rem;
		z-index: 100;
}